import { render, staticRenderFns } from "./CreatePlanStepFour.vue?vue&type=template&id=044f5dbb&scoped=true"
import script from "./CreatePlanStepFour.vue?vue&type=script&lang=js"
export * from "./CreatePlanStepFour.vue?vue&type=script&lang=js"
import style0 from "./CreatePlanStepFour.vue?vue&type=style&index=0&id=044f5dbb&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "044f5dbb",
  null
  
)

export default component.exports