<template>
    <div class="PlanStepSucceed">
        <i class="iconfont">&#xe730;</i>
        <span>恭喜您！完成推广！</span>
        <div class="PlanStepSucceedBtn">
            <el-button class="ckBtn" @click="toNext">查看推广详情</el-button>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: "CreatePlanStepFour",
        data(){
            return {

            }
        },
        mounted() {
            // if (Number(this.$lockr.get('competition_type')) === 1) {
            //     this.updateMenu();
            // }
        },
        methods: {
            ...mapActions([
                "setFormData"
            ]),
            toNext(){
                this.setFormData(null)
                this.$router.push({
                    path:'/student/operationPromotion/index'
                })
            },
            // updateMenu() {
            //     let data = {
            //         offset1: 2,
            //         offset2: 4,
            //         status: 2
            //     }
            //     this.$http.axiosGetBy(this.$api.data_setUserOpList, data, (res) => {
            //         if (res.code === 200) {
            //             this.setDataMenusList();
            //         } else {
            //             this.$message.warning(res.msg);
            //         }
            //     }, (err) => {
            //         this.$common.axiosErrorMsg(err);
            //     })
            // },
        }
    }
</script>

<style scoped lang="scss">
    .PlanStepSucceed{
        height: calc(100vh - 400px);
        padding-top: 104px;
        padding-bottom: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .iconfont{
            font-size: 60px;
            color: #20D08C;
        }
        span{
            color: #333333;
            font-size: 24px;
            font-weight: 500;
            margin-top: 20px;
        }
        .PlanStepSucceedBtn{
            margin-top: 40px;
        }
        .ckBtn{
            background-color: #FD4446;
            border-color: #FD4446;
            color: #ffffff;
        }
    }
</style>
